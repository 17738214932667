$(document).ready ->
  resizeAll = (firstTime) ->
    windowHeight = $(window).height();
    windowWidth = $(window).width();
    headerHeight = $('header').outerHeight();

    mobile = if windowWidth >= 768 then false else true
    desktop = !mobile

    wrapperHeight = windowHeight;

    $('#wrapper').css('minHeight', wrapperHeight - headerHeight);

    if desktop
      if $('.wrapper-plan').size()
        minHeight = $('.container-content').height() + 120 + headerHeight * 2
        if wrapperHeight - headerHeight > minHeight
          minHeight = wrapperHeight - headerHeight
        $('.wrapper-plan > .container').css('minHeight', minHeight)
        $('#wrapper').css('minHeight', minHeight);


        #$('.wrapper-plan .container-content').css('marginTop', ($('.wrapper-plan > .container').height() - $('.wrapper-plan .container-content').height() - 250) / 2)

        $('.background-left').css('width', 2 + parseInt((windowWidth - $('.container-content').outerWidth()) / 2))
        $('.background-right').css('width', 2 + parseInt((windowWidth - $('.container-content').outerWidth()) / 2))

    if desktop
      $('.desktop').show();
      $('.mobile').hide();
      #if windowHeight < 700
      #  $('h1').hide();
      #else
      #  $('h1').show();
        
      #$('.wrapper-homepage > .container').css('paddingTop', parseInt((wrapperHeight - $('.wrapper-homepage > .container').height()) / 2))
      


      buttonWidth = 123;
      #$('.prev-next.prev, .prev-next.next').css('top', windowHeight / 2 - buttonWidth / 2 + headerHeight / 2);
      if windowWidth > 1000
        freeSpace = (windowWidth - $('#wrapper > .container').width()) / 2
        
        $('.prev-next.prev').css('left', -freeSpace + freeSpace / 2 - buttonWidth / 2);
        $('.prev-next.next').css('right', -freeSpace + freeSpace / 2 - buttonWidth / 2);


    else
      $('.desktop').hide();
      $('.mobile').show();
        
      #$('#wrapper > .container').css('paddingTop', (wrapperHeight - $('#wrapper > .container').height()) / 2);
    
    


  $(window).on 'resize', resizeAll
  resizeAll(true)


  $('body').on 'click', '.btn-share-fb, .btn-share-twitter', (e) ->
    e.preventDefault()
    window.open(this.href, '', 'height=300, width=600, top=' + ((screen.height / 2) - 150) + ', left=' + ((screen.width / 2) - 350) + ', toolbar=no, menubar=no, scrollbars=no, resizable=no, location=no, directories=no, status=no');

  $('body').on 'click', '.credits', (e) ->
    e.preventDefault();
    $('.popup-overlay').fadeIn();
    $('.popup-credits').fadeIn();
  $('body').on 'click', '.btn-jc', (e) ->
    e.preventDefault();
    #$('.popup-overlay').fadeIn();
    #$('.popup-jc').fadeIn();


    if window.ga?
      window.ga('send', 'event', 'Minisite', 'Affichage formulaire');

  $('body').on 'click', 'a[target=_blank]', (e) ->
    #e.preventDefault();

    if window.ga?
      window.ga('send', 'event', 'Clic externe', $(this).attr('href'));

  $('body').on 'click', '.popup-close,.popup-overlay', (e) ->
    $('.popup').fadeOut();
    $('.popup-overlay').fadeOut();
  $('body').on 'touchstart', '.popup-close,.popup-overlay', (e) ->
    $('.popup').fadeOut();
    $('.popup-overlay').fadeOut();


###
   Licensed under the Apache License, Version 2.0 (the "License");
   you may not use this file except in compliance with the License.
   You may obtain a copy of the License at

   http://www.apache.org/licenses/LICENSE-2.0

   Unless required by applicable law or agreed to in writing, software
   distributed under the License is distributed on an "AS IS" BASIS,
   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   See the License for the specific language governing permissions and
   limitations under the License.
###

defaultDiacriticsRemovalap = [
  {
    'base': 'A'
    'letters': 'AⒶＡÀÁÂẦẤẪẨÃĀĂẰẮẴẲȦǠÄǞẢÅǺǍȀȂẠẬẶḀĄȺⱯ'
  }
  {
    'base': 'AA'
    'letters': 'Ꜳ'
  }
  {
    'base': 'AE'
    'letters': 'ÆǼǢ'
  }
  {
    'base': 'AO'
    'letters': 'Ꜵ'
  }
  {
    'base': 'AU'
    'letters': 'Ꜷ'
  }
  {
    'base': 'AV'
    'letters': 'ꜸꜺ'
  }
  {
    'base': 'AY'
    'letters': 'Ꜽ'
  }
  {
    'base': 'B'
    'letters': 'BⒷＢḂḄḆɃƂƁ'
  }
  {
    'base': 'C'
    'letters': 'CⒸＣĆĈĊČÇḈƇȻꜾ'
  }
  {
    'base': 'D'
    'letters': 'DⒹＤḊĎḌḐḒḎĐƋƊƉꝹ'
  }
  {
    'base': 'DZ'
    'letters': 'ǱǄ'
  }
  {
    'base': 'Dz'
    'letters': 'ǲǅ'
  }
  {
    'base': 'E'
    'letters': 'EⒺＥÈÉÊỀẾỄỂẼĒḔḖĔĖËẺĚȄȆẸỆȨḜĘḘḚƐƎ'
  }
  {
    'base': 'F'
    'letters': 'FⒻＦḞƑꝻ'
  }
  {
    'base': 'G'
    'letters': 'GⒼＧǴĜḠĞĠǦĢǤƓꞠꝽꝾ'
  }
  {
    'base': 'H'
    'letters': 'HⒽＨĤḢḦȞḤḨḪĦⱧⱵꞍ'
  }
  {
    'base': 'I'
    'letters': 'IⒾＩÌÍÎĨĪĬİÏḮỈǏȈȊỊĮḬƗ'
  }
  {
    'base': 'J'
    'letters': 'JⒿＪĴɈ'
  }
  {
    'base': 'K'
    'letters': 'KⓀＫḰǨḲĶḴƘⱩꝀꝂꝄꞢ'
  }
  {
    'base': 'L'
    'letters': 'LⓁＬĿĹĽḶḸĻḼḺŁȽⱢⱠꝈꝆꞀ'
  }
  {
    'base': 'LJ'
    'letters': 'Ǉ'
  }
  {
    'base': 'Lj'
    'letters': 'ǈ'
  }
  {
    'base': 'M'
    'letters': 'MⓂＭḾṀṂⱮƜ'
  }
  {
    'base': 'N'
    'letters': 'NⓃＮǸŃÑṄŇṆŅṊṈȠƝꞐꞤ'
  }
  {
    'base': 'NJ'
    'letters': 'Ǌ'
  }
  {
    'base': 'Nj'
    'letters': 'ǋ'
  }
  {
    'base': 'O'
    'letters': 'OⓄＯÒÓÔỒỐỖỔÕṌȬṎŌṐṒŎȮȰÖȪỎŐǑȌȎƠỜỚỠỞỢỌỘǪǬØǾƆƟꝊꝌ'
  }
  {
    'base': 'OI'
    'letters': 'Ƣ'
  }
  {
    'base': 'OO'
    'letters': 'Ꝏ'
  }
  {
    'base': 'OU'
    'letters': 'Ȣ'
  }
  {
    'base': 'OE'
    'letters': 'Œ'
  }
  {
    'base': 'oe'
    'letters': 'œ'
  }
  {
    'base': 'P'
    'letters': 'PⓅＰṔṖƤⱣꝐꝒꝔ'
  }
  {
    'base': 'Q'
    'letters': 'QⓆＱꝖꝘɊ'
  }
  {
    'base': 'R'
    'letters': 'RⓇＲŔṘŘȐȒṚṜŖṞɌⱤꝚꞦꞂ'
  }
  {
    'base': 'S'
    'letters': 'SⓈＳẞŚṤŜṠŠṦṢṨȘŞⱾꞨꞄ'
  }
  {
    'base': 'T'
    'letters': 'TⓉＴṪŤṬȚŢṰṮŦƬƮȾꞆ'
  }
  {
    'base': 'TZ'
    'letters': 'Ꜩ'
  }
  {
    'base': 'U'
    'letters': 'UⓊＵÙÚÛŨṸŪṺŬÜǛǗǕǙỦŮŰǓȔȖƯỪỨỮỬỰỤṲŲṶṴɄ'
  }
  {
    'base': 'V'
    'letters': 'VⓋＶṼṾƲꝞɅ'
  }
  {
    'base': 'VY'
    'letters': 'Ꝡ'
  }
  {
    'base': 'W'
    'letters': 'WⓌＷẀẂŴẆẄẈⱲ'
  }
  {
    'base': 'X'
    'letters': 'XⓍＸẊẌ'
  }
  {
    'base': 'Y'
    'letters': 'YⓎＹỲÝŶỸȲẎŸỶỴƳɎỾ'
  }
  {
    'base': 'Z'
    'letters': 'ZⓏＺŹẐŻŽẒẔƵȤⱿⱫꝢ'
  }
  {
    'base': 'a'
    'letters': 'aⓐａẚàáâầấẫẩãāăằắẵẳȧǡäǟảåǻǎȁȃạậặḁąⱥɐ'
  }
  {
    'base': 'aa'
    'letters': 'ꜳ'
  }
  {
    'base': 'ae'
    'letters': 'æǽǣ'
  }
  {
    'base': 'ao'
    'letters': 'ꜵ'
  }
  {
    'base': 'au'
    'letters': 'ꜷ'
  }
  {
    'base': 'av'
    'letters': 'ꜹꜻ'
  }
  {
    'base': 'ay'
    'letters': 'ꜽ'
  }
  {
    'base': 'b'
    'letters': 'bⓑｂḃḅḇƀƃɓ'
  }
  {
    'base': 'c'
    'letters': 'cⓒｃćĉċčçḉƈȼꜿↄ'
  }
  {
    'base': 'd'
    'letters': 'dⓓｄḋďḍḑḓḏđƌɖɗꝺ'
  }
  {
    'base': 'dz'
    'letters': 'ǳǆ'
  }
  {
    'base': 'e'
    'letters': 'eⓔｅèéêềếễểẽēḕḗĕėëẻěȅȇẹệȩḝęḙḛɇɛǝ'
  }
  {
    'base': 'f'
    'letters': 'fⓕｆḟƒꝼ'
  }
  {
    'base': 'g'
    'letters': 'gⓖｇǵĝḡğġǧģǥɠꞡᵹꝿ'
  }
  {
    'base': 'h'
    'letters': 'hⓗｈĥḣḧȟḥḩḫẖħⱨⱶɥ'
  }
  {
    'base': 'hv'
    'letters': 'ƕ'
  }
  {
    'base': 'i'
    'letters': 'iⓘｉìíîĩīĭïḯỉǐȉȋịįḭɨı'
  }
  {
    'base': 'j'
    'letters': 'jⓙｊĵǰɉ'
  }
  {
    'base': 'k'
    'letters': 'kⓚｋḱǩḳķḵƙⱪꝁꝃꝅꞣ'
  }
  {
    'base': 'l'
    'letters': 'lⓛｌŀĺľḷḹļḽḻſłƚɫⱡꝉꞁꝇ'
  }
  {
    'base': 'lj'
    'letters': 'ǉ'
  }
  {
    'base': 'm'
    'letters': 'mⓜｍḿṁṃɱɯ'
  }
  {
    'base': 'n'
    'letters': 'nⓝｎǹńñṅňṇņṋṉƞɲŉꞑꞥ'
  }
  {
    'base': 'nj'
    'letters': 'ǌ'
  }
  {
    'base': 'o'
    'letters': 'oⓞｏòóôồốỗổõṍȭṏōṑṓŏȯȱöȫỏőǒȍȏơờớỡởợọộǫǭøǿɔꝋꝍɵ'
  }
  {
    'base': 'oi'
    'letters': 'ƣ'
  }
  {
    'base': 'ou'
    'letters': 'ȣ'
  }
  {
    'base': 'oo'
    'letters': 'ꝏ'
  }
  {
    'base': 'p'
    'letters': 'pⓟｐṕṗƥᵽꝑꝓꝕ'
  }
  {
    'base': 'q'
    'letters': 'qⓠｑɋꝗꝙ'
  }
  {
    'base': 'r'
    'letters': 'rⓡｒŕṙřȑȓṛṝŗṟɍɽꝛꞧꞃ'
  }
  {
    'base': 's'
    'letters': 'sⓢｓßśṥŝṡšṧṣṩșşȿꞩꞅẛ'
  }
  {
    'base': 't'
    'letters': 'tⓣｔṫẗťṭțţṱṯŧƭʈⱦꞇ'
  }
  {
    'base': 'tz'
    'letters': 'ꜩ'
  }
  {
    'base': 'u'
    'letters': 'uⓤｕùúûũṹūṻŭüǜǘǖǚủůűǔȕȗưừứữửựụṳųṷṵʉ'
  }
  {
    'base': 'v'
    'letters': 'vⓥｖṽṿʋꝟʌ'
  }
  {
    'base': 'vy'
    'letters': 'ꝡ'
  }
  {
    'base': 'w'
    'letters': 'wⓦｗẁẃŵẇẅẘẉⱳ'
  }
  {
    'base': 'x'
    'letters': 'xⓧｘẋẍ'
  }
  {
    'base': 'y'
    'letters': 'yⓨｙỳýŷỹȳẏÿỷẙỵƴɏỿ'
  }
  {
    'base': 'z'
    'letters': 'zⓩｚźẑżžẓẕƶȥɀⱬꝣ'
  }
]
diacriticsMap = {}
# "what?" version ... http://jsperf.com/diacritics/12

removeDiacritics = (str) ->
  str.replace /[^\u0000-\u007E]/g, (a) ->
    diacriticsMap[a] or a
clearString = (str) ->
  str = removeDiacritics(str).toLowerCase()
  str = str.replace(/\W+/g, " ")

i = 0
while i < defaultDiacriticsRemovalap.length
  letters = defaultDiacriticsRemovalap[i].letters
  j = 0
  while j < letters.length
    diacriticsMap[letters[j]] = defaultDiacriticsRemovalap[i].base
    j++
  i++
